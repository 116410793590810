import { sdk } from "@gc/ipecs-web-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { storageKeys } from "../constants";

const DevicesContext = React.createContext();
const DevicesProvider = ({ children }) => {
  const { call } = sdk;

  const [devices, setDevices] = useState([]);
  const [uniqueDevices, setUniqueDevices] = useState([]);
  const [audioAllowed, setAudioAllowed] = useState(true);
  const [audioInput, setAudioInput] = useState();
  const [audioOutput, setAudioOutput] = useState();
  const [videoInput, setVideoInput] = useState();

  const handleInitDevices = useCallback(async () => {
    try {
      const _permissions = await call.getMediaDevicesPermission({
        audio: true,
        video: true,
      });
      setAudioAllowed(_permissions?.audio === "allowed");
      const devices = await call.getMediaDevicesInfo();
      // the same physical hardware can be returned multiple times
      // by browsers, handle this
      const uniqueDevices = devices.reduce((acc, curr) => {
        const exists = acc.find(
          ({ groupId, kind }) => groupId === curr.groupId && kind === curr.kind,
        );
        if (!exists) acc.push(curr);

        return acc;
      }, []);

      setDevices(devices);
      setUniqueDevices(uniqueDevices);
    } catch (e) {
      toast("Failed to load media devices", { type: "error" });
    }
  }, [call]);

  useEffect(() => {
    handleInitDevices();
  }, [handleInitDevices]);

  useEffect(() => {
    const devices = JSON.parse(localStorage.getItem(storageKeys.DEVICES));
    if (devices) {
      if (devices.audio_output) setAudioOutput(devices.audio_output);
      if (devices.audio_input) setAudioInput(devices.audio_input);
      if (devices.video_input) setVideoInput(devices.video_input);
    }
  }, []);

  const setAudioOutputDevice = async (deviceId) => {
    const devices = JSON.parse(localStorage.getItem(storageKeys.DEVICES));
    const _devices = devices ? devices : {};
    _devices.audio_output = deviceId;
    await call.changeAudioOutputRequest(deviceId);
    localStorage.setItem(storageKeys.DEVICES, JSON.stringify(_devices));
    setAudioOutput(deviceId);
  };

  const setAudioInputDevice = async (deviceId) => {
    const devices = JSON.parse(localStorage.getItem(storageKeys.DEVICES));
    const _devices = devices ? devices : {};
    _devices.audio_input = deviceId;
    await call.changeAudioInputRequest(deviceId);
    localStorage.setItem(storageKeys.DEVICES, JSON.stringify(_devices));
    setAudioInput(deviceId);
  };

  const setVideoInputDevice = async (deviceId) => {
    const devices = JSON.parse(localStorage.getItem(storageKeys.DEVICES));
    const _devices = devices ? devices : {};
    _devices.video_input = deviceId;
    await call.changeVideoDeviceRequest(deviceId);
    localStorage.setItem(storageKeys.DEVICES, JSON.stringify(_devices));
    setVideoInput(deviceId);
  };

  const defaultContext = {
    handleInitDevices,
    audioAllowed,
    devices,
    uniqueDevices,
    audioInput,
    audioOutput,
    videoInput,
    setAudioOutputDevice,
    setAudioInputDevice,
    setVideoInputDevice,
  };
  return (
    <DevicesContext.Provider value={defaultContext}>
      {children}
    </DevicesContext.Provider>
  );
};

function useDevices() {
  return useContext(DevicesContext);
}

export { DevicesProvider, useDevices };
