import React from "react";
import { usePhoneControl } from "../../contexts/PhoneControlContext";
import ActiveAudioCall from "../activeCall/ActiveAudioCall";
import ActiveCallActionBar from "../activeCall/ActiveCallActionBar";
import ActiveCallConnecting from "../activeCall/ActiveCallConnecting";
import ActiveCallContainer from "../activeCall/ActiveCallContainer";

const style = {
  push: {
    width: "100%",
    height: "calc(100% - 100px)",
    zIndex: 2,
  },
};

function ActivePhoneControlCall() {
  const {
    minimized,
    connecting,
    activeCall,
    callAnimationRef,
    activeCallPartyInfo,
    handleCallAnswered,
    setCallDuration,
    numberInvalid,
  } = usePhoneControl();

  return (
    <ActiveCallContainer minimized={minimized}>
      <div style={style.push}></div>
      {connecting && (
        <ActiveCallConnecting invalid={numberInvalid} minimized={minimized} />
      )}
      {!connecting && activeCall && (
        <ActiveAudioCall
          minimized={minimized}
          activeCallPartyInfo={activeCallPartyInfo}
          callAnimationRef={callAnimationRef}
          onHold={false}
          transferNumber=""
          handleCallAnswered={handleCallAnswered}
          setCallDuration={setCallDuration}
          numberInvalid={numberInvalid}
        />
      )}
      <ActionBar />
    </ActiveCallContainer>
  );
}

function ActionBar() {
  const {
    minimized,
    setMinimized,
    disconnecting,
    activeCall,
    activeCallPartyInfo,
    handleHangup,
  } = usePhoneControl();

  return (
    <ActiveCallActionBar
      minimized={minimized}
      setMinimized={setMinimized}
      disconnecting={disconnecting}
      activeCall={activeCall}
      activeCallPartyInfo={activeCallPartyInfo}
      handleHangup={handleHangup}
    />
  );
}

export default ActivePhoneControlCall;
