import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";

function MakeCall() {
  const { number } = useParams();
  const { handleMakeCall } = useCalls();
  const { hasFeature } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFeature("click_to_dial")) {
      if (number) {
        // prefix with + for non UK numbers
        const numberToDial =
          number.length >= 10 &&
          number.substring(0, 1) !== "0" &&
          number.substring(0, 1) !== "+"
            ? "+" + number
            : number;

        handleMakeCall(numberToDial, "audio", 2500);
      }
      navigate("/calls");
    } else {
      toast("You don't have access to the Click to Dial feature", {
        type: "error",
        toastId: "ctd-error",
      });
      navigate("/calls");
    }
  }, [number, handleMakeCall, hasFeature, navigate]);

  return null;
}

export default MakeCall;
