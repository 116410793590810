import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Segment } from "semantic-ui-react";

const style = {
  container: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    height: "calc(100% - 100px)",
    zIndex: 1,
  },
};

function ActiveAudioCall({
  minimized,
  activeCallPartyInfo,
  callAnimationRef,
  onHold,
  transferNumber,
  handleCallAnswered,
  setCallDuration,
}) {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    handleCallAnswered();
    const timerInterval = setInterval(
      () => setTimer((oldTimer) => oldTimer + 1),
      1000,
    );
    return () => clearInterval(timerInterval);
  }, [handleCallAnswered]);

  useEffect(() => {
    setCallDuration(timer);
  }, [timer, setCallDuration]);

  return (
    <div style={style.container}>
      <Segment basic textAlign="center">
        {!minimized && (
          <>
            {activeCallPartyInfo?.avatar === undefined ||
            activeCallPartyInfo?.avatar.substring(0, 7) === "initial" ? (
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: callAnimationRef.current,
                }}
                height={minimized ? 60 : 400}
                width={minimized ? 60 : 400}
              />
            ) : (
              <img
                src={activeCallPartyInfo?.avatar}
                alt="Avatar"
                style={{
                  maxHeight: minimized ? 60 : 400,
                  maxWidth: minimized ? 60 : 400,
                  borderRadius: "12px",
                }}
              />
            )}
          </>
        )}

        <TextAnimation>
          {onHold ? "Call on hold" : "Call in Progress"}
          {transferNumber && transferNumber.length > 0 && (
            <p>Transferring to {transferNumber}</p>
          )}
        </TextAnimation>

        <p>
          {Math.floor(timer / 60).toLocaleString("en-GB", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          :
          {(timer % 60).toLocaleString("en-GB", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </p>
      </Segment>
    </div>
  );
}

function TextAnimation({ children }) {
  return (
    <motion.p
      style={{ marginTop: "20px" }}
      animate={{
        opacity: [1, 1, 0.5, 1, 1],
      }}
      transition={{
        duration: 5,
        ease: "easeInOut",
        times: [0, 0.4, 0.6, 0.8, 1],
        repeat: Infinity,
      }}
    >
      {children}
    </motion.p>
  );
}

export default ActiveAudioCall;
