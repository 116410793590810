import { sdk } from "@gc/ipecs-web-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import call1Animation from "../animations/call1.json";
import { postCallAnswered, postCallEnded, postCallStarted } from "../api/calls";
import { lookupByCallerid } from "../api/contacts";
import ActivePhoneControlCall from "../components/phoneControl/ActivePhoneControlCall";
import IncomingPhoneControlCallAlert from "../components/phoneControl/IncomingPhoneControlCallAlert";
import { closePopupDialler, focusIncomingCall } from "../helpers/clickToDial";
import { convertToE164 } from "../helpers/convertToE164";
import { useAuth } from "./AuthContext";
import { useCallLogs } from "./CallLogsContext";

const callAnimations = [call1Animation];

const PhoneControlContext = React.createContext();
const PhoneControlProvider = ({ children }) => {
  const { phoneControl } = sdk;
  const { refetchCallLogs } = useCallLogs();
  const { apiUser } = useAuth();

  const [minimized, setMinimized] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [activeCall, setActiveCall] = useState();
  const [activeCallPartyInfo, setActiveCallPartyInfo] = useState();
  const [incomingCallPartyInfo, setIncomingCallPartyInfo] = useState();
  const [incomingCall, setIncomingCall] = useState();
  const [callDirection, setCallDirection] = useState();
  const [callDuration, setCallDuration] = useState();

  const [eventCallEnded, setEventCallEnded] = useState();
  const [eventCallConnected, setEventCallConnected] = useState();
  const [eventCallReceived, setEventCallReceived] = useState();
  const [eventTransferToReceived, setEventTransferToReceived] = useState();

  const callAnimationRef = useRef();

  const handleCloseOverlay = useCallback(() => {
    setIncomingCall();
    setConnecting(false);
    setNumberInvalid(false);
    refetchCallLogs();
    closePopupDialler();
  }, [refetchCallLogs]);

  const handleCallAnswered = useCallback(async () => {
    try {
      await postCallAnswered({
        direction: callDirection,
        number: activeCall.callNumber,
      });
    } catch {
      // do nothing
    }
  }, [callDirection, activeCall]);

  const handleCallEnded = useCallback(async () => {
    try {
      if (activeCall) {
        await postCallEnded({
          provider_id: activeCall.ticket,
          duration: callDuration,
          direction: callDirection,
          number: activeCall.callNumber,
        });
      }
    } catch {
      // do nothing
    } finally {
      setActiveCallPartyInfo();
      setActiveCall();
      setCallDirection();
      setCallDuration();
    }
  }, [callDirection, callDuration, activeCall]);

  // prevent refresh if call is ongoing
  useEffect(() => {
    window.onbeforeunload = function () {
      if (activeCall) return false;
      // else do nothing
    };
    console.log("active call:");
    console.log(activeCall);
  }, [activeCall]);

  // unset incoming call party info if no incoming call
  useEffect(() => {
    if (!incomingCall) {
      setIncomingCallPartyInfo();
    }
    console.log("incoming call:");
    console.log(incomingCall);
  }, [incomingCall, setIncomingCallPartyInfo]);

  // setup event listeners
  useEffect(() => {
    phoneControl.onPhoneControlCallAlert((data) => {
      setActiveCall(data);
      if (data?.ticket && data.peerId) {
        phoneControl
          .phoneControlGetOtherPartyInfo({
            ticket: data.ticket,
            peerId: data.peerId,
          })
          .then((res) => setActiveCallPartyInfo(res))
          .catch(() => setActiveCallPartyInfo());
      }
    });

    phoneControl.onPhoneControlCallEnded(({ ticket }) => {
      setEventCallEnded(ticket);
    });

    phoneControl.onPhoneControlCallConnected(({ ticket }) => {
      setEventCallConnected(ticket);
    });

    phoneControl.onPhoneControlCallReceived((event) => {
      setEventCallReceived(event);
    });

    phoneControl.onPhoneControlCallTransferred((event) => {
      setEventTransferToReceived(event);
    });
  }, [phoneControl]);

  // handle call ended
  useEffect(() => {
    if (
      eventCallEnded &&
      (!activeCall || activeCall.ticket === eventCallEnded)
    ) {
      setActiveCallPartyInfo();
      handleCloseOverlay();
      setEventCallEnded();
      handleCallEnded();
    }
  }, [eventCallEnded, activeCall, handleCloseOverlay, handleCallEnded]);

  // handle call connected
  useEffect(() => {
    const handle = async () => {
      if (eventCallConnected) {
        if (activeCall) {
          if (eventCallConnected === activeCall.ticket) {
            setActiveCall({ ...activeCall, state: "answered" });
            setConnecting(false);
          }
        } else if (incomingCall) {
          setActiveCall({ ...incomingCall, state: "answered" });
          setIncomingCall();
          // set a random animation
          callAnimationRef.current =
            callAnimations[Math.floor(Math.random() * callAnimations.length)];
        }
      }
    };

    handle();
    setEventCallConnected();
  }, [eventCallConnected, activeCall, incomingCall, phoneControl]);

  // handle incoming call received
  useEffect(() => {
    if (eventCallReceived) {
      if (!activeCall) {
        setCallDirection("INBOUND");
        setIncomingCall(eventCallReceived);

        const { ticket, callNumber, mediaType } = eventCallReceived;

        if (incomingCall?.ticket !== ticket) {
          phoneControl
            .phoneControlGetOtherPartyInfo({
              ticket: ticket,
              peerId: eventCallReceived.peerId,
            })
            .then((res) => {
              const notification = new Notification(
                `Incoming ${mediaType} call`,
                {
                  body: `From ${res.name}`,
                  icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                },
              );
              notification.onclick = () => window.focus();

              setIncomingCallPartyInfo(res);
            })
            .catch((e) => {
              const e164Number = convertToE164(
                callNumber,
                apiUser?.phone_locality,
              );

              lookupByCallerid(e164Number, 3000)
                .then((data) => {
                  const contact = data?.data?.data;
                  const contactName =
                    contact?.first_name || contact?.last_name
                      ? [contact?.first_name, contact?.last_name].join(" ")
                      : callNumber;

                  const notification = new Notification(`Incoming call`, {
                    body: `From ${contactName}`,
                    icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                  });
                  notification.onclick = () => window.focus();
                })
                .catch(() => {
                  const notification = new Notification(`Incoming call`, {
                    body: `From ${callNumber}`,
                    icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                  });
                  notification.onclick = () => window.focus();
                });
            });

          // Send a message to the Click to Dial extension to optionally focus an open Connect tab
          focusIncomingCall();
        }
      } else {
        console.log(
          "incoming call event recieved, but active call not cleared:",
        );
        console.log(activeCall);
      }

      setEventCallReceived();
    }
  }, [eventCallReceived, activeCall, incomingCall, apiUser, phoneControl]);

  const handleMakeCall = (destinationNumber, mediaType, delay = 0) => {
    if (activeCall) {
      toast("You're already on a call", { type: "error" });
      return;
    }

    startCall(destinationNumber, mediaType, delay);
  };

  const startCall = useCallback(
    async (destinationNumber, mediaType, delay = 0) => {
      setCallDirection("OUTBOUND");
      const destinationNumberWithSuffix = apiUser?.dial_suffix
        ? destinationNumber + apiUser.dial_suffix
        : destinationNumber;

      try {
        if (destinationNumber.length > 4) {
          setConnecting(true);
          setMinimized(false);
        }

        // optionally delay sending the request to ipecs
        if (delay > 0) {
          await new Promise((r) => setTimeout(r, delay));
        }

        await phoneControl.phoneCtrlMakeCall({
          destinationNumber: destinationNumberWithSuffix,
          mediaType: mediaType,
        });

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: destinationNumber,
          });
        } catch {
          // do nothing
        }

        try {
          setActiveCallPartyInfo(
            await phoneControl.phoneControlGetOtherPartyInfo({
              ticket: activeCall.ticket,
              peerId: activeCall.peerId,
            }),
          );
        } catch (e) {
          setActiveCallPartyInfo();
        }
      } catch (e) {
        switch (e.message) {
          case "E_CALL_PARAM_ERROR":
            toast(`Invalid number | ${destinationNumber}`, { type: "error" });
            setNumberInvalid(true);
            break;
          default:
            toast(`Failed to call | ${destinationNumber}`, { type: "error" });
        }

        setConnecting(false);
      }
    },
    [phoneControl, apiUser.dial_suffix, activeCall],
  );

  const handleHangup = async () => {
    if (activeCall) {
      setDisconnecting(true);
      try {
        await phoneControl.phoneCtrlEndCall(activeCall.ticket);
      } catch (e) {
        // do nothing
      }
      setDisconnecting(false);
      await handleCallEnded();
    }

    handleCloseOverlay();
  };

  const handleRejectIncoming = async () => {
    try {
      if (incomingCall) {
        setDisconnecting(true);
        await phoneControl.phoneCtrlEndCall(incomingCall.ticket);
        await handleCallEnded();
        setDisconnecting(false);
        handleCloseOverlay();
      }
    } catch (e) {
      setIncomingCall();
      throw e;
    }
  };

  const handleAnswerIncoming = async () => {
    if (incomingCall) {
      try {
        setActiveCall(incomingCall);
        await phoneControl.phoneCtrlAnswerCall({ ticket: incomingCall.ticket });

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        setIncomingCall();

        try {
          setActiveCallPartyInfo(
            await phoneControl.phoneControlGetOtherPartyInfo({
              ticket: incomingCall.ticket,
              peerId: incomingCall.peerId,
            }),
          );
        } catch (e) {
          setActiveCallPartyInfo();
        }
      } catch (e) {
        setActiveCall();
        toast("Failed to answer call", { type: "error" });
        throw e;
      }
    }
  };

  // handle transfer to received
  useEffect(() => {
    if (eventTransferToReceived && activeCall) {
      const newActiveCall = {
        ...activeCall,
        ticket: eventTransferToReceived.ticket,
        peerId: eventTransferToReceived.peerId,
        roomId: eventTransferToReceived.roomId,
        callNumber: eventTransferToReceived.callNumber,
      };

      setActiveCall(newActiveCall);
      setActiveCallPartyInfo({
        avatar: undefined,
        name: eventTransferToReceived.callNumber,
        callNumber: eventTransferToReceived.callNumber,
      });

      setEventTransferToReceived();
    }
  }, [eventTransferToReceived, activeCall]);

  const defaultContext = {
    minimized,
    setMinimized,
    connecting,
    activeCall,
    activeCallPartyInfo,
    incomingCall,
    incomingCallPartyInfo,
    disconnecting,
    numberInvalid,
    handleMakeCall,
    handleHangup,
    handleCallAnswered,
    callAnimationRef,
    setCallDuration,
    handleAnswerIncoming,
    handleRejectIncoming,
  };

  return (
    <PhoneControlContext.Provider value={defaultContext}>
      {(connecting || activeCall) && <ActivePhoneControlCall />}
      {incomingCall && <IncomingPhoneControlCallAlert />}
      {children}
    </PhoneControlContext.Provider>
  );
};

function usePhoneControl() {
  return useContext(PhoneControlContext);
}

export { PhoneControlProvider, usePhoneControl };
